import { ActiveRecord, CustomHttpProvider } from 'enginearch-ng2-active-record';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import moment from 'moment';
import 'moment-timezone';
import { CONFIG } from './app/config';
import { AppModule } from './app/root/app.module';
const { enableMapSet, setAutoFreeze } = require('immer');
const elf = require('@ngneat/elf');

enableMapSet();
setAutoFreeze(false);
ActiveRecord.http = CustomHttpProvider.instance;

export const environment = CONFIG.environment;
if (CONFIG.environment === 'production') {
	enableProdMode();
	elf.enableElfProdMode();
} else {
	// elf.registerPreStoreUpdate((currentState, nextState, storeName) => {
	// 	// return deepFreeze(nextState);
	// 	return nextState
	// });
}

const bootstrap: any = () => {
	return platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((e) => {
			console.error(e);
		});
};

bootstrap();

// MONKEY PATCH FOR MOMENT
// moment.locale('en')
const originalGetZone = moment.tz.zone;
(<any>moment).tz.zone = (name, caller) => {
	name = (name || '').toLowerCase().replace(/\//g, '_');
	let zone = (<any>moment).tz._zones[name];
	let cloned;
	let link;

	if (zone instanceof (<any>moment).tz.Zone) {
		cloned = new (<any>moment).tz.Zone();
		cloned._set(zone);
		return cloned;
	}

	if (typeof zone === 'string') {
		zone = new (<any>moment).tz.Zone(zone);
		(<any>moment).tz._zones[name] = zone;
		cloned = new (<any>moment).tz.Zone();
		cloned._set(zone);
		return cloned;
	}

	// Pass getZone to prevent recursion more than 1 level deep
	if (
		(<any>moment).tz._links[name] &&
		caller !== (<any>moment).tz.zone &&
		(<any>moment).tz.zone((<any>moment).tz._links[name], (<any>moment).tz.zone)
	) {
		link = (<any>moment).tz.zone((<any>moment).tz._links[name], (<any>moment).tz.zone);
		zone = (<any>moment).tz._zones[name] = new (<any>moment).tz.Zone();
		zone._set(link);
		zone.name = (<any>moment).tz._names[name];
		cloned = new (<any>moment).tz.Zone();
		cloned._set(zone);
		return cloned;
	}

	return null;
};

(<any>moment).tz.setDefault = (name) => {
	if (
		parseInt(moment.version.split('.')[0]) < 2 ||
		(parseInt(moment.version.split('.')[0]) === 2 && parseInt(moment.version.split('.')[1]) < 9)
	) {
		console.error(
			'Moment Timezone setDefault() requires Moment.js >= 2.9.0. You are using Moment.js ' +
				moment.version +
				'.'
		);
	}
	(<any>moment).defaultZone = name ? (<any>moment).tz.zone(name) : null;
	return moment;
};
// END OF MONKEY PATCH FOR MOMENT


(<any>window).getClosestParent = (elem, selector) => {
	// Element.matches() polyfill
	if (!Element.prototype.matches) {
		Element.prototype.matches =
			(<any>Element).prototype.matchesSelector ||
			(<any>Element).prototype.mozMatchesSelector ||
			(<any>Element).prototype.msMatchesSelector ||
			(<any>Element).prototype.oMatchesSelector ||
			(<any>Element).prototype.webkitMatchesSelector ||
			function (s) {
				var matches = (this.document || this.ownerDocument).querySelectorAll(s),
					i = matches.length;
				while (--i >= 0 && matches.item(i) !== this) {}
				return i > -1;
			};
	}

	// Get the closest matching element
	for (; elem && elem !== document; elem = elem.parentNode) {
		if (elem.matches(selector)) return elem;
	}
	return null;
};
